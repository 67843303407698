.profile-left-side,
.profile-right-side {
  background-color: #ffffff;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  border-radius: 5px;
}
.profile-left-side-wrapper {
  text-align: center;
  padding: 20px 0;
}

.profile-right-info ul li {
  display: flex;
  margin-bottom: 10px;
  border-bottom: 1px solid #dee2e6;
  padding-bottom: 10px;
}
.profile-right-info ul li:last-child {
  border-bottom: none;
}
.profile-right-info ul li span {
  width: 25%;
  font-weight: 600;
  font-size: 1rem;
}
.profile-right-info ul li p {
  padding-left: 10px;
  font-size: 0.9rem;
}
.change-password-input {
  width: 100%;
  height: 45px;
  border: 1px solid gray;
  padding-left: 10px;
  border-radius: 5px;
}
.profile-left-side-link-active {
  text-decoration: none;
  color: #1988cf;
}
.profile-left-side-link {
  text-decoration: none;
  color: black;
}
.online {
  background-color: #6c757d;
  color: white;
  padding: 2px 10px 3px 10px;
  border-radius: 30px;
}
.paid {
  background-color: #009a16;
  color: white;
  padding: 2px 10px 3px 10px;
  border-radius: 30px;
}
.light-style .dropzone {
  border: 2px dashed #d4d8dd;
}
.dropzone {
  width: 100%;
  position: relative;
  padding: 1.5rem;
  cursor: pointer;
  border-radius: 0.3125rem;
}
.droppable-image-div {
  position: relative;
}
.droppable-image-remove {
  position: absolute;
  top: -5px;
  right: 0;
}

.approved {
  color: green;
  font-weight: bold;
}
.pending {
  color: #f57f17;
  font-weight: bold;
}
