.social-links {
    padding: .5rem;
}
.social-links span{
    width: 95.8%;
    display: flex;
    justify-content: end;

}


.social-links span a {
    color: black;
    padding:5px;
}
