@media only screen and (max-width: 575px) {
  .footer-logo img {
    width: 160px;
  }
}

.footer-newsletter {
  padding-top: 32px;
}

.footer-newsletter p {
  font-size: 18px;
  font-weight: 400;
  color: #ffffff;
}

.footer-wrap a {
  text-decoration: none;
  color: rgb(255, 255, 255);
  font-size: 14px;
}

.footer-wrap a:hover {
  color: red;
}


.footer-wrap {
  background-color: #3A3A3A;
  padding-top: 5rem;
}
.footer-wrap li {
  list-style-type: none;
}

@media only screen and (max-width: 575px) {
  .footer-newsletter p {
    font-size: 16px;
  }
}

.footer-newsletter-form {
  max-width: 750px;
  margin: 18px auto 0;
  position: relative;
}

.footer-newsletter-form input {
  width: 100%;
  height: 60px;
  font-size: 18px;
  font-weight: 400;
  color: #93a1a2;
  padding: 0 30px;
  padding-right: 195px;
  background-color: #ffffff;
  border: 0;
}

@media only screen and (max-width: 575px) {
  .footer-newsletter-form input {
    height: 50px;
    padding: 0 25px;
    font-size: 16px;
  }
}

.footer-newsletter-form .btn {
  position: absolute;
  top: 0;
  right: 0;
  height: 60px;
  line-height: 60px;
  min-width: 165px;
}

@media only screen and (max-width: 575px) {
  .footer-newsletter-form .btn {
    position: relative;
    width: 100%;
    height: 50px;
    line-height: 50px;
    margin-top: 10px;
  }
}

.footer-widget-social {
  display: flex;
  gap: 48px;
  justify-content: center;
  margin-top: 38px;
}

@media only screen and (max-width: 575px) {
  .footer-widget-social {
    gap: 24px;
  }
}

.footer-widget-social a {
  font-size: 36px;
  color: #a3a3cb;
  line-height: 1.1;
}

@media only screen and (max-width: 575px) {
  .footer-widget-social a {
    font-size: 24px;
  }
}

.footer-widget-social a:hover {
  color: #fc097c;
}

.footer-copyright {
  margin-top: 42px;
}

.footer-copyright p {
  /* color: #a3a3cb; */
}

.meeta-footer-section.meeta-footer-2 {
  padding-bottom: 0;
}

.meeta-footer-2 .footer-widget .footer-wrap {
  padding-bottom: 80px;
}

.meeta-footer-2 .footer-newsletter {
  padding-top: 0;
}

@media only screen and (max-width: 1199px) {
  .meeta-footer-2 .footer-newsletter {
    padding-left: 50px;
  }
}

@media only screen and (max-width: 991px) {
  .meeta-footer-2 .footer-newsletter {
    padding-left: 0;
    padding-top: 50px;
  }
}

.meeta-footer-2 .footer-newsletter .footer-newsletter-form {
  margin-top: 0;
  margin-right: 0;
}

.meeta-footer-2 .footer-bottom-wrap {
  padding-left: 125px;
  margin-top: 80px;
}

@media only screen and (max-width: 1199px) {
  .meeta-footer-2 .footer-bottom-wrap {
    padding-left: 50px;
  }
}

@media only screen and (max-width: 991px) {
  .meeta-footer-2 .footer-bottom-wrap {
    padding-left: 0px;
  }
}

@media only screen and (max-width: 767px) {
  .meeta-footer-2 .footer-bottom-wrap {
    margin-top: 60px;
  }
}

.meeta-footer-2 .footer-bottom-wrap .footer-info .title {
  font-size: 22px;
  line-height: 30px;
  font-family: 'Archivo', sans-serif;
  font-weight: 700;
  color: #ffffff;
}

.meeta-footer-2 .footer-bottom-wrap .footer-info .date {
  font-size: 14px;
  line-height: 30px;
  font-family: 'Open Sans', sans-serif;
  font-weight: 700;
  color: #fc097c;
  display: inline-block;
  margin-top: 20px;
}

.meeta-footer-2 .footer-bottom-wrap .footer-info p {
  font-size: 14px;
  line-height: 30px;
  font-family: 'Open Sans', sans-serif;
  font-weight: 400;
  color: #ffffff;
}

.meeta-footer-2 .footer-widget-social {
  gap: 20px;
  justify-content: flex-start;
  margin-top: 30px;
}

.meeta-footer-2 .footer-widget-social a {
  font-size: 18px;
}

@media only screen and (max-width: 767px) {
  .meeta-footer-2 .footer-widget-map {
    margin-top: 40px;
  }
}

.meeta-footer-2 .footer-widget-map iframe,
.meeta-footer-3 .footer-widget-map iframe {
  width: 100%;
  height: 200px;
  filter: brightness(100%) contrast(100%) saturate(0%) blur(0px)
    hue-rotate(0deg);
}

.meeta-footer-2 .footer-copyright {
  border-top: 3px solid #1e0c53;
  padding-top: 40px;
  padding-bottom: 45px;
  margin-top: 0;
}

.meeta-footer-2 .footer-copyright p {
  font-size: 14px;
  line-height: 24px;
  font-family: 'Open Sans', sans-serif;
  color: #a3a3cb;
}

.meeta-footer-section.meeta-footer-3 {
  padding-bottom: 0;
}

.meeta-footer-3 {
  position: relative;
  z-index: 1;
}

.meeta-footer-3 .footer-widget-wrap {
  padding-bottom: 100px;
  margin-top: -40px;
}

@media only screen and (max-width: 991px) {
  .meeta-footer-3 .footer-widget-wrap {
    padding-bottom: 80px;
  }
}

.meeta-footer-3 .footer-widget-wrap .footer-widget .widget-title {
  font-size: 18px;
  line-height: 30px;
  font-family: 'Archivo', sans-serif;
  font-weight: 700;
  color: #ffffff;
  margin-bottom: 20px;
}

.meeta-footer-3 .footer-widget-wrap .footer-widget .footer-logo img {
  border-radius: 15px;
}

.meeta-footer-3 .footer-widget-wrap .footer-widget .widget-text .date {
  font-size: 14px;
  line-height: 30px;
  font-family: 'Open Sans', sans-serif;
  font-weight: 700;
  color: #fc097c;
}

.meeta-footer-3 .footer-widget-wrap .footer-widget .widget-text p {
  font-size: 14px;
  line-height: 24px;
  font-family: 'Open Sans', sans-serif;
  font-weight: 400;
  color: #ffffff;
}

.meeta-footer-3 .footer-widget-wrap .footer-widget .widget-text .btn-2 {
  height: 45px;
  line-height: 45px;
  font-size: 14px;
  border-radius: 5px;
  margin-top: 20px;
}

.meeta-footer-3 .footer-widget-wrap .footer-widget .widget-info ul li {
  display: flex;
  /* align-items: center; */
}

.meeta-footer-3 .footer-widget-wrap .footer-widget .widget-info ul li + li {
  margin-top: 5px;
}

.i {
  font-size: 17px;
  color: #fc097c;
  margin-right: 10px;
}
.info-text span {
  font-size: 14px;
  line-height: 30px;
  font-family: 'Open Sans', sans-serif;
  font-weight: 400;
  color: #ffffff;
  text-decoration: none;
}
.info-text span a {
  font-size: 14px;
  line-height: 30px;
  font-family: 'Open Sans', sans-serif;
  font-weight: 400;
  color: #ffffff;
  text-decoration: none;
}

.meeta-footer-3 .footer-widget-wrap .footer-widget .widget-form {
  position: relative;
}

.meeta-footer-3 .footer-widget-wrap .footer-widget .widget-form .form-control {
  height: 45px;
  background-color: #f0f0f0;
  color: #93a1a2;
  font-weight: 400;
  border: 0;
  padding-left: 20px;
  padding-right: 50px;
  font-size: 14px;
  border-radius: 5px;
}

.meeta-footer-3
  .footer-widget-wrap
  .footer-widget
  .widget-form
  .form-control:focus {
  box-shadow: none;
}

.meeta-footer-3 .footer-widget-wrap .footer-widget .widget-form button {
  width: 45px;
  height: 45px;
  line-height: 45px;
  background: #fc097c;
  border: 0;
  color: #ffffff;
  position: absolute;
  right: 0;
  top: 0;
  font-size: 14px;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}

.meeta-footer-3 .footer-widget-wrap .footer-widget .footer-widget-social {
  gap: 25px;
  justify-content: flex-start;
  margin-top: 20px;
}

.meeta-footer-3 .footer-widget-wrap .footer-widget .footer-widget-social a {
  font-size: 15px;
  color: #ffffff;
  transition: all 0.5s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.meeta-footer-3
  .footer-widget-wrap
  .footer-widget
  .footer-widget-social
  a:hover {
  color: #fc097c;
}

.meeta-footer-3 .footer-copyright {
  background: transparent;
  padding-top: 30px;
  padding-bottom: 30px;
  margin-top: 0;
  border-top: 1px solid #4a555f;
}

.meeta-footer-4 {
  position: relative;
  z-index: 1;
}

.meeta-footer-4::before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  background: rgba(26, 10, 30, 0.7);
  width: 100%;
  height: 100%;
  z-index: -1;
}

.meeta-footer-4 .footer-widget .footer-newsletter {
  background: #554bc0;
  max-width: 950px;
  margin: 0 auto;
  padding-top: 45px;
  padding: 40px 50px 60px;
  margin-top: 60px;
}

@media only screen and (max-width: 1199px) {
  .meeta-footer-4 .footer-widget .footer-newsletter {
    padding: 30px 50px 60px;
  }
}

.meeta-footer-4 .footer-widget .footer-newsletter .title {
  font-size: 44px;
  line-height: 70px;
  font-family: 'Archivo', sans-serif;
  font-weight: 700;
  color: #ffffff;
}

@media only screen and (max-width: 991px) {
  .meeta-footer-4 .footer-widget .footer-newsletter .title {
    font-size: 36px;
    line-height: 48px;
  }
}

@media only screen and (max-width: 575px) {
  .meeta-footer-4 .footer-widget .footer-newsletter .title {
    font-size: 30px;
    line-height: 40px;
  }
}

.meeta-footer-4 .footer-widget .footer-newsletter .footer-newsletter-form {
  margin-top: 10px;
}

@media only screen and (max-width: 991px) {
  .meeta-footer-4 .footer-widget .footer-newsletter .footer-newsletter-form {
    margin-top: 20px;
  }
}

.meeta-footer-4
  .footer-widget
  .footer-newsletter
  .footer-newsletter-form
  .btn::before {
  display: none;
}

.meeta-footer-4 .footer-widget .footer-widget-social {
  gap: 20px;
}

.meeta-footer-4 .footer-widget .footer-widget-social a {
  font-size: 18px;
  width: 45px;
  height: 45px;
  line-height: 43px;
  border: 1px solid #ffffff;
  border-radius: 50%;
  color: #ffffff;
}

.meeta-footer-4 .footer-widget .footer-widget-social a:hover {
  color: #fc097c;
}

.footer-menu ul li {
  list-style: none;
}

.footer-menu ul li a {
  font-size: 16px;
  line-height: 28px;
  text-decoration: none;
  font-family: 'Archivo', sans-serif;
  display: block;
  color: #ffffff;
  transition: all 0.5s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.footer-menu ul li a:hover {
  color: #fc097c;
}

.meeta-footer-5 {
  padding-top: 225px;
  padding-bottom: 65px;
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
}

.meeta-footer-5 .footer-widget-social {
  border-top: 1px solid #4a555f;
  gap: 20px;
  padding-top: 35px;
  margin-top: 70px;
}

.meeta-footer-5 .footer-widget-social a {
  height: 45px;
  width: 45px;
  line-height: 45px;
  background-color: #34404b;
  border-radius: 50%;
  font-size: 22px;
  color: #ffffff;
  transition: all 0.5s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.meeta-footer-5 .footer-widget-social a:hover {
  color: #fc097c;
}

.meeta-footer-5 .footer-copyright p {
  color: #919191;
}

/*--
/* 31. Footer CSS
/*----------------------------------------*/
/* .meeta-footer-section {
  background-image: url('../../public//assets/images/bg/footer-bg.jpg');
  background-color: #311a79;
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  padding: 100px 0;
} */

@media only screen and (max-width: 991px) {
  .meeta-footer-section {
    padding: 80px 0;
  }
}
